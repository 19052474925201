import React from 'react'

import { DefaultWelcomeBanner } from '../components/shared/auth-page/DefaultWelcomeBanner'

const editorStyle = {
  fontFamily: 'Arial',
  padding: '0 1rem',
  backgroundColor: '#ffffff',
  borderBottomLeftRadius: '0.4rem',
  borderBottomRightRadius: '0.4rem',
  fontSize: 'small',
  borderTop: '0'
}

const toolbarStyle = {
  border: '0',
  borderTopLeftRadius: '0.4rem',
  borderTopRightRadius: '0.4rem',
  background: '#EDF2F7',
  marginBottom: '0',
  display: 'flex',
  justifyContent: 'space-between'
}

const wrapperStyle = {
  width: '100%',
  border: '1px solid #D5E1ED',
  borderRadius: '0.4rem',
  backgroundColor: 'white'
}

const DEFAULT_IMAGES = {
  registerBanner: <DefaultWelcomeBanner />,
  loginBanner: <DefaultWelcomeBanner />
}

const getDefaultAuthLinkHtml = (domain: string) => {
  return {
    en: {
      alreadyHaveAccount: `<span>Do you already have an account? <a href="https://${domain}/login">Please sign in here.</a></span>`,
      notRegisteredYet: `<span>Not registered yet? <a href="https://${domain}/register">Please register here.</a></span>`
    },
    de: {
      alreadyHaveAccount: `<span>Haben Sie bereits ein Konto? <a href="https://${domain}/login">Bitte melde dich hier an.</a></span>`,
      notRegisteredYet: `<span>Noch nicht registriert? <a href="https://${domain}/register">Bitte registrieren Sie sich hier.</a></span>`
    }
  }
}

const DEFAULT_CONFIG_HTML = {
  en: {
    termsConditions:
      '<span>By clicking on “Register” you agree to ours <a href="https://epilot.cloud">Terms of Use. </a>In our <a href="/login">Data policy</a> learn how we collect, use and share your data. Our <a href="https://epilot.cloud">Cookie policy</a> explains how we use cookies and similar technologies. Facebook may send you notifications by SMS , which you can unsubscribe at any time.</span>',
    privacySettings: `<span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</span>`
  },
  de: {
    termsConditions:
      '<span>Indem Sie auf „Registrieren“ klicken, stimmen Sie unseren <a href="https://epilot.cloud">Nutzungsbedingungen</a> zu. In unserer <a href="https://epilot.cloud">Cookie-Richtlinie</a> erfahren Sie, wie wir Ihre Daten erfassen, verwenden und teilen.</span>',
    privacySettings: `<span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</span>`
  }
}

function getContrastingColor(hexColor: string) {
  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)

  // Calculate the relative luminance (per ITU-R BT.709)
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Choose a contrasting color based on luminance
  if (luminance > 0.5) {
    // If the background is light, return a dark color
    return '#000000' // You can change this to your preferred dark color
  } else {
    // If the background is dark, return a light color
    return '#FFFFFF' // You can change this to your preferred light color
  }
}

export {
  editorStyle,
  wrapperStyle,
  toolbarStyle,
  DEFAULT_CONFIG_HTML,
  DEFAULT_IMAGES,
  getContrastingColor,
  getDefaultAuthLinkHtml
}
