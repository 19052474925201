import { Auth } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

const PORTAL_ID_LS_KEY = 'PORTAL_ID_LS_KEY'
const TOKEN_LS_KEY = 'token'

const getToken = async (): Promise<string> => {
  try {
    const session: CognitoUserSession = await Auth.currentSession()

    const idtoken = session?.getIdToken()

    setUserToken(idtoken.getJwtToken())

    return idtoken.getJwtToken()
  } catch (err) {
    return null
  }
}

const isJsonEmpty = (jsonObject) => {
  return Object.keys(jsonObject).length === 0
}

const setUserToken = (token: string): void => {
  localStorage.setItem(TOKEN_LS_KEY, token)
}

const getPortalId = (): string => {
  return localStorage.getItem(PORTAL_ID_LS_KEY)
}

const setPortalId = (portalId: string): void => {
  localStorage.setItem(PORTAL_ID_LS_KEY, portalId)
}

export default {
  getToken,
  getPortalId,
  setPortalId,
  isJsonEmpty
}
