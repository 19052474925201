import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export enum QUERY_PARAM {
  orgId = 'orgId',
  theme = 'theme',
  email = 'email',
  code = 'code',
  contactId = 'contactId',
  designId = 'designId',
  origin = 'origin',
  preview = 'preview'
}

export const useQueryParams = (): URLSearchParams => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
