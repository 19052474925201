import { Auth } from '@aws-amplify/auth'
import { Amplify, Hub } from '@aws-amplify/core'
import type { HubCapsule } from '@aws-amplify/core/lib-esm/Hub'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useQueryClient } from 'react-query'
import { useEffectOnce } from 'react-use'

import AuthUtils from 'utils/AuthUtils'

import { ENV_CONFIG } from '../EnvConfig'
import { isConfigMode, isPreviewMode } from '../utils/StyleUtils'

import PortalConfigContext, {
  PortalConfigContextState
} from './PortalConfigContext'

export interface User {
  id: string
  username: string // primary id for user
  attributes: {
    'custom:portal_user_id': string
    sub: string
    'custom:brand_id': string
    email_verified: boolean
    'custom:contact_entity_id': string
    'custom:org_id': string
    'custom:origin': string
    email: string
  }
}

const AUTH_HUB_CHANNEL = 'auth'
const HUB_CAPSULE_STATUS = {
  CONFIGURED: 'configured',
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut'
}

export interface AuthContextState {
  data: AuthContextData
  actions: AuthContextActions
}

export interface AuthContextData {
  initialized: boolean
  authenticated: boolean
  user: User
  session: CognitoUserSession
  isAdminSession: boolean
}

export interface AuthContextActions {
  setIsAuthenticated: (authenticated: boolean) => void
}

const defaultState: AuthContextState = {
  data: null,
  actions: {
    setIsAuthenticated: () => null
  }
}

const CONFIG_USER: User = {
  id: 'id',
  username: 'username',
  attributes: {
    sub: 'sub',
    email: 'john-doe@mail.com',
    email_verified: true,
    'custom:portal_user_id': 'portal_user_id',
    'custom:brand_id': 'brand_id',
    'custom:contact_entity_id': 'contact_entity_id',
    'custom:org_id': 'org_id',
    'custom:origin': 'origin'
  }
}

const AuthContext = createContext<AuthContextState>(defaultState)

export interface AuthContextProviderProps {
  children: ReactNode
}

// TypeGuard to check if promise is fulfilled
function isFulfilled<T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled'
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const queryClient = useQueryClient()
  const { data: configData } =
    useContext<PortalConfigContextState>(PortalConfigContext)

  const [initialized, setInitialized] = useState(false)
  const [authenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<User>(null)
  const [session, setSession] = useState<CognitoUserSession>(null)
  const [isAdminSession, setIsAdminSession] = useState<boolean>(false)

  useEffectOnce(() => {
    if (isConfigMode()) {
      setUser(CONFIG_USER)
    }
  })

  useEffectOnce(() => {
    if (isPreviewMode()) {
      setInitialized(true)
    }
  })

  // configure amplify from portal config
  useEffect(() => {
    if (configData?.portalConfig?.cognito_details) {
      Amplify.configure({
        authenticationFlowType: 'USER_SRP_AUTH',
        region: ENV_CONFIG.AMPLIFY_REGION,
        userPoolId:
          configData.portalConfig.cognito_details.cognito_user_pool_id,
        userPoolWebClientId:
          configData.portalConfig.cognito_details.cognito_user_pool_client_id
      })
    }
  }, [configData?.portalConfig?.cognito_details])

  useEffect(() => {
    if (isPreviewMode()) {
      return null
    }

    const authListener = async (hubCapsule: HubCapsule) => {
      const result = await Promise.allSettled([
        Auth.currentUserInfo(),
        Auth.currentSession()
      ])

      const cognitoUserInfo = isFulfilled<User>(result[0])
        ? result[0].value
        : null
      const session = isFulfilled<CognitoUserSession>(result[1])
        ? result[1].value
        : null

      const idTokenPayload = session ? session.getIdToken().payload : null

      // First configuration of amplify, set data if any
      if (hubCapsule.payload.event === HUB_CAPSULE_STATUS.CONFIGURED) {
        if (cognitoUserInfo && !AuthUtils.isJsonEmpty(cognitoUserInfo)) {
          setUser(cognitoUserInfo)
          setIsAuthenticated(true)
        }

        if (session) {
          setSession(session)
        }

        if (idTokenPayload) {
          setIsAdminSession(!!idTokenPayload['admin_user_id'])
        }

        setInitialized(true)
      }

      if (hubCapsule.payload.event === HUB_CAPSULE_STATUS.SIGN_IN) {
        queryClient.removeQueries()
        setUser(cognitoUserInfo)
        setSession(session)
        setIsAdminSession(!!idTokenPayload['admin_user_id'])

        setIsAuthenticated(true)
      }

      if (hubCapsule.payload.event === HUB_CAPSULE_STATUS.SIGN_OUT) {
        setIsAuthenticated(false)

        setUser(null)
        setSession(null)
        setIsAdminSession(false)
      }
    }

    Hub.listen(AUTH_HUB_CHANNEL, authListener)

    return () => Hub.remove(AUTH_HUB_CHANNEL, authListener)
  }, [queryClient])

  return (
    <AuthContext.Provider
      value={{
        data: { initialized, authenticated, user, session, isAdminSession },
        actions: { setIsAuthenticated }
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContextProvider }
export default AuthContext
