import { evaluateActionAgainstRoles } from '@epilot/permissions/rules'
import { useContext } from 'react'

import PortalConfigContext, {
  Grant,
  PermissionsAction
} from 'context/PortalConfigContext'

import { EntitySchemaItem } from '../api/ApiTypes'
import { useContextSchema } from '../context/SchemasContext'

import { EntityType } from './EntityUtils'
import { isConfigMode, isPreviewMode } from './StyleUtils'

const isGrantPermitted = (
  grants: Grant[],
  action: PermissionsAction,
  resource: string
): boolean => {
  if (isConfigMode() || isPreviewMode()) {
    return true
  }

  return evaluateActionAgainstRoles({
    roles: [
      {
        id: '_',
        name: 'root',
        slug: 'root',
        type: 'org_role',
        organization_id: '_',
        grants: [{ action: '*', resource: '*' }]
      },
      {
        id: '_',
        name: 'user',
        slug: 'user',
        type: 'user_role',
        organization_id: '_',
        grants: grants
      }
    ],
    action: action,
    resource: resource
  })
}

const isOneAttributeFromGroupPermittedForView = (
  grants: Grant[],
  schemaName: string,
  groupName: string,
  attributes: string[]
): boolean => {
  const isWholeGroupPermitted = isGrantPermitted(
    grants,
    PermissionsAction.ATTRIBUTE_VIEW,
    `${schemaName}:${groupName}:*`
  )

  if (isWholeGroupPermitted) {
    return true
  }

  const atLeastOneViewAttributeEnabled = attributes.some((attribute: string) =>
    isGrantPermitted(
      grants,
      PermissionsAction.ATTRIBUTE_VIEW,
      `${schemaName}:${groupName}:${attribute}`
    )
  )

  if (atLeastOneViewAttributeEnabled) {
    return true
  }

  return isOneAttributeFromGroupPermittedForEdit(
    grants,
    schemaName,
    groupName,
    attributes
  )
}

const isOneAttributeFromGroupPermittedForEdit = (
  grants: Grant[],
  schemaName: string,
  groupName: string,
  attributes: string[]
): boolean => {
  const isWholeGroupPermitted = isGrantPermitted(
    grants,
    PermissionsAction.ATTRIBUTE_EDIT,
    `${schemaName}:${groupName}:*`
  )

  if (isWholeGroupPermitted) {
    return true
  }

  const atLeastOneAttributeEnabled = attributes.some((attribute: string) =>
    isGrantPermitted(
      grants,
      PermissionsAction.ATTRIBUTE_EDIT,
      `${schemaName}:${groupName}:${attribute}`
    )
  )

  return atLeastOneAttributeEnabled
}

export const usePrivacySettingsPermissions = () => {
  const MARKETING_CONSENTS_ATTRIBUTES = [
    'consent_sms_marketing',
    'consent_print_marketing',
    'consent_phone_call',
    'consent_email_marketing'
  ]

  const { actions: portalConfigActions, data: portalConfigData } =
    useContext(PortalConfigContext)

  const isOneMarketingAttributePermittedForView = () => {
    const isWholeGroupPermitted = isGrantPermitted(
      portalConfigData.portalConfig.grants,
      PermissionsAction.ATTRIBUTE_VIEW,
      `contact:Marketing Consent Management:*`
    )

    if (isWholeGroupPermitted) {
      return true
    }

    const oneAttributeEnabledForView = MARKETING_CONSENTS_ATTRIBUTES.some(
      (attribute: string) =>
        isGrantPermitted(
          portalConfigData.portalConfig.grants,
          PermissionsAction.ATTRIBUTE_VIEW,
          `contact:Marketing Consent Management:${attribute}`
        )
    )

    const oneAttributeEnabledForEdit = MARKETING_CONSENTS_ATTRIBUTES.some(
      (attribute: string) =>
        isGrantPermitted(
          portalConfigData.portalConfig.grants,
          PermissionsAction.ATTRIBUTE_EDIT,
          `contact:Marketing Consent Management:${attribute}`
        )
    )

    return oneAttributeEnabledForView || oneAttributeEnabledForEdit
  }

  const isEditSmsPermitted = portalConfigActions.isPermitted(
    PermissionsAction.ATTRIBUTE_EDIT,
    'contact:Marketing Consent Management:consent_sms_marketing'
  )
  const isViewSmsPermitted =
    portalConfigActions.isPermitted(
      PermissionsAction.ATTRIBUTE_VIEW,
      'contact:Marketing Consent Management:consent_sms_marketing'
    ) || isEditSmsPermitted

  const isEditPrintPermitted = portalConfigActions.isPermitted(
    PermissionsAction.ATTRIBUTE_EDIT,
    'contact:Marketing Consent Management:consent_print_marketing'
  )

  const isViewPrintPermitted =
    portalConfigActions.isPermitted(
      PermissionsAction.ATTRIBUTE_VIEW,
      'contact:Marketing Consent Management:consent_print_marketing'
    ) || isEditPrintPermitted

  const isEditPhonePermitted = portalConfigActions.isPermitted(
    PermissionsAction.ATTRIBUTE_EDIT,
    'contact:Marketing Consent Management:consent_phone_call'
  )

  const isViewPhonePermitted =
    portalConfigActions.isPermitted(
      PermissionsAction.ATTRIBUTE_VIEW,
      'contact:Marketing Consent Management:consent_phone_call'
    ) || isEditPhonePermitted

  const isEditEmailPermitted = portalConfigActions.isPermitted(
    PermissionsAction.ATTRIBUTE_EDIT,
    'contact:Marketing Consent Management:consent_email_marketing'
  )

  const isViewEmailPermitted =
    portalConfigActions.isPermitted(
      PermissionsAction.ATTRIBUTE_VIEW,
      'contact:Marketing Consent Management:consent_email_marketing'
    ) || isEditEmailPermitted

  const isEditCompanyDetailsPermitted = portalConfigActions.isPermitted(
    PermissionsAction.ATTRIBUTE_EDIT,
    'contact:Company Details:*'
  )

  const isViewCompanyDetailsPermitted =
    portalConfigActions.isPermitted(
      PermissionsAction.ATTRIBUTE_VIEW,
      'contact:Company Details:*'
    ) || isEditCompanyDetailsPermitted

  return {
    isOneMarketingAttributePermittedForView,
    isViewEmailPermitted,
    isViewPhonePermitted,
    isViewSmsPermitted,
    isViewPrintPermitted,
    isEditEmailPermitted,
    isEditPhonePermitted,
    isEditSmsPermitted,
    isEditPrintPermitted,
    isViewCompanyDetailsPermitted,
    isEditCompanyDetailsPermitted
  }
}

export const useContactPermissions = () => {
  const contactSchema = useContextSchema(EntityType.CONTACT)
  const { data: portalConfigData } = useContext(PortalConfigContext)

  const nothingPermitted = (): boolean => {
    const getGroupAttributes = (groupName: string): string[] =>
      (contactSchema.schema as EntitySchemaItem)?.attributes
        .filter((att) => att.group === groupName)
        .map((att) => att.name) || []

    const personalDetailsAttributes = getGroupAttributes('Personal Details')

    const contactDetailsAttributes = getGroupAttributes('Contact Details')

    const addressDetailsAttributes = getGroupAttributes('Address Details')

    const paymentDetailsAttributes = getGroupAttributes('Payment Details')

    const isViewPersonalInformationGroupPermitted =
      isOneAttributeFromGroupPermittedForView(
        portalConfigData.portalConfig.grants,
        'contact',
        'Personal Details',
        personalDetailsAttributes
      )

    const isViewContactDetailsGroupPermitted =
      isOneAttributeFromGroupPermittedForView(
        portalConfigData.portalConfig.grants,
        'contact',
        'Contact Details',
        contactDetailsAttributes
      )

    const isViewAddressInformationGroupPermitted =
      isOneAttributeFromGroupPermittedForView(
        portalConfigData.portalConfig.grants,
        'contact',
        'Address Details',
        addressDetailsAttributes
      )

    const isViewPaymentDetailsGroupPermitted =
      isOneAttributeFromGroupPermittedForView(
        portalConfigData.portalConfig.grants,
        'contact',
        'Payment Details',
        paymentDetailsAttributes
      )

    return (
      !isViewPersonalInformationGroupPermitted &&
      !isViewContactDetailsGroupPermitted &&
      !isViewAddressInformationGroupPermitted &&
      !isViewPaymentDetailsGroupPermitted
    )
  }

  return { nothingPermitted }
}

const isViewGroupPermitted = (schema: string, group: string, grants: Grant[]) =>
  isGrantPermitted(
    grants,
    PermissionsAction.ATTRIBUTE_VIEW,
    `${schema}:${group}:*`
  )

export default {
  isGrantPermitted,
  isOneAttributeFromGroupPermittedForView,
  isOneAttributeFromGroupPermittedForEdit,
  isViewGroupPermitted
}
