import { Icon, Tooltip } from '@epilot/base-elements'
import React from 'react'
import styled from 'styled-components/macro'

import useEcpTranslation from '../../../hooks/common/useEcpTranslation'

interface ResetTranslationProps {
  onClick: () => void
  context?: 'text' | 'image'
}

const ResetTranslation = ({
  onClick,
  context = 'text'
}: ResetTranslationProps) => {
  const { t } = useEcpTranslation()

  return (
    <Tooltip
      placement="top"
      title={t('common.config.reset_tooltip', {
        context,
        default: 'Reset to default value'
      })}
    >
      <IconContainer onClick={onClick}>
        <Icon color="primary" fontSize="small" name="refresh" />
      </IconContainer>
    </Tooltip>
  )
}

interface RemoveTranslationProps {
  onClick: (path: string) => void
}

const RemoveTranslation = ({ onClick }: RemoveTranslationProps) => {
  return (
    <IconContainer onClick={onClick}>
      <Icon color="primary" fontSize="small" name="delete" />
    </IconContainer>
  )
}

export { ResetTranslation, RemoveTranslation }

const IconContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 0.1rem 0.4rem rgba(21, 43, 66, 0.16);
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  padding: 0 0.8rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 1.5rem;
  }
`
